<template>
    <div class="page-table mainDiv scrollable only-y" id="affix-container" >
        <div class="card-base baslikBg animated fadeInUp">
            {{$t("src.views.apps.urun.liste.title")}}

            <div class="tanimlamaButon">
               
                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button v-on:click="refreshPage()" style="margin-left: 15px !important" type="text" icon="el-icon-refresh" class="routeButton"></el-button>
                </el-tooltip>

                <el-tooltip :content='$t("src.views.apps.genel.yeniKaydet")' :open-delay="500" placement="bottom">
                    <el-button v-on:click="routeUrun('Ürün Tanımla')" style="margin-left: 15px !important" type="text" icon="el-icon-plus"></el-button>
                </el-tooltip>
            </div>
        </div>

        <div class="page-header card-base card-shadow--medium animated fadeInUp">
            <el-row class="mt-0 mb-10">
                <el-col :lg="20" :md="20" :sm="24" :xs="24">
                    <div>
                        <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9" text-color="gray">
                            <el-radio-button label="1" :disabled="loading">
                                <i class="mdi mdi-cards-outline mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                                <transition name="slide-fade">
                                    <label v-if="selectIcon==='1'">{{$t("src.views.apps.urun.liste.activeProduct")}}</label>
                                </transition>
                            </el-radio-button>                                          
                            <el-radio-button label="0" :disabled="loading">
                                <i class="mdi mdi-cards-outline mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                                <transition name="slide-fade">
                                    <label v-if="selectIcon==='0'">{{$t("src.views.apps.urun.liste.passiveProduct")}}</label>
                                </transition>
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </el-col>
                <el-col class="margin-bottom-10" :lg="4" :md="4" :sm="24" :xs="24">
                    <el-radio-group style="float: right" size="mini" v-on:change="handleChangeListe($event)" v-model="radioListe" fill="#E5F5F9" text-color="gray">
                        <el-radio-button label="1">
                            <i class="mdi mdi-format-list-bulleted mdi-18px"></i>
                            <transition name="slide-fade"><label v-if="listeTipi==='1'"></label>
                            </transition>
                        </el-radio-button>
                        <el-radio-button label="2">
                            <i class="mdi mdi-image-size-select-actual mdi-18px"></i>
                            <transition name="slide-fade"><label v-if="listeTipi==='2'"></label>
                            </transition>
                        </el-radio-button>
                    </el-radio-group>
                </el-col>
            </el-row>


            <el-row>
                <!-- SİDEBAR AÇMA KAPAMA TUŞU BAŞLANGIÇ -->
                <el-col v-if="isDesktop">
                    <div class="mb-5">
                        <el-tooltip :content='sidebarOpen ? "Filtre Menüsünü Kapat" : "Filtre Menüsünü Aç"' :open-delay="250" placement="top">
                            <el-button class="rotate-icon" style="font-size:20px!important" type="primary" :icon="sidebarOpen ? 'el-icon-s-fold' : 'el-icon-s-unfold'" @click="sidebarOpen = !sidebarOpen; listKey++"></el-button>
                        </el-tooltip>
                    </div>
                </el-col>
                <!-- SİDEBAR AÇMA KAPAMA TUŞU BİTİŞ -->

                <!-- SİDEBAR BAŞLANGIÇ -->
                <el-col :lg="5" :md="5" :sm="24" :xs="24" id="leftSideBar" :class="[sidebarOpen ? 'animated fadeInLeft' : 'animated bounceOutLeft']">
                    <div  class="box grow flex">
                        <div class="page-ecommerce-products flex" style="width: 100%">
                            <div :class="{'sidebar':true}" >
                                <vue-scroll class="scroller">
                                    <div class="widget">
                                        <div class="title">{{$t("src.views.apps.urun.liste.searchProduct")}}</div>
                                        <div class="content">
                                            <el-row>
                                                <el-col :lg="24" :md="24" :sm="24" :xs="24">
                                                    <el-input clearable v-on:clear="filterProduct" v-debounce:250ms="filterProduct" v-model="productFilterName" placeholder="Ürün İsmi Ara" size="mini">
                                                        <!-- <el-button slot="append" icon="el-icon-search" @click="filterProduct"></el-button> -->
                                                    </el-input>
                                                </el-col>
                                            </el-row>
                                        </div>
                                        <br v-if="!isDesktop">
                                        <br v-if="!isDesktop">
                                    </div>   
                                </vue-scroll>
                            </div>
                        </div>
                    </div>
                </el-col>
                <!-- SİDEBAR BİTİŞ -->

                <!-- ÜRÜN LİST BAŞLANGIÇ -->
                <el-col class="animated fadeIn" :lg="urunlerCol" :md="urunlerCol" :sm="urunlerCol" :xs="urunlerCol">
                    <div class="box grow flex">
                        <div class="page-ecommerce-products flex" style="width: 100%">
                            <!-- LİSTE TİPİ 1 OLAN YANİ NORMAL DÜZ TABLO OLANLAR BAŞLANGIÇ -->
                            <el-table v-if="listeTipi === '1'" border stripe :data="urunlerList"  v-loading="loading || searchLoading"
                                :element-loading-text='loading ? $t("src.views.apps.urun.liste.loading") : $t("src.views.apps.urun.liste.searchLoading")'
                                element-loading-spinner="el-icon-loading"
                                element-loading-background="rgba(255, 255, 255, 1)">

                                <el-table-column type="expand">
                                    <template slot-scope="props">
                                        <div class="right-box box grow">
                                            <blockquote>
                                                <el-row class="mb-5">
                                                    <el-col :lg="4" :md="4" :sm="4" :xs="4">İçerik</el-col>
                                                    <el-col :lg="1" :md="1" :sm="1" :xs="1">:</el-col>
                                                    <el-col :lg="19" :md="19" :sm="19" :xs="19">{{props.row.icerik}}</el-col>
                                                </el-row>
                                            </blockquote>
                                        </div>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    :label='$t("src.views.apps.genel.sira")'
                                    type="index"
                                    :index="indexMethod"
                                    width="70" align="center">
                                </el-table-column>
                               
                                <el-table-column
                                    :label='$t("src.views.apps.urun.liste.table.image")'
                                    width="500" align="center">
                                    <template slot-scope="scope">
                                        <el-popover transition="el-fade-in-linear" placement="right" width="auto" trigger="hover">
                                            <img style="max-height: 500px" v-if="scope.row.resim" :src="imagepath+scope.row.resim" :alt="scope.row.baslik">
                                            <img v-if="scope.row.resim" style="max-height: 50px !important" slot="reference" :src="imagepathKucuk+scope.row.resim" :alt="scope.row.baslik">
                                            <i v-else style="font-size: 24px; cursor: pointer; color: #337ab7" slot="reference" class="el-icon-picture"></i>
                                        </el-popover>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    sortable
                                    show-overflow-tooltip
                                    prop="baslik"
                                    label='Başlık'
                                    width="auto">
                                    <template slot-scope="prop">
                                        <div class="new-line-text">
                                            {{prop.row.baslik}}
                                        </div>
                                    </template>
                                </el-table-column>     
                                <el-table-column
                                    sortable
                                    show-overflow-tooltip
                                    prop="altBaslik"
                                    label='Alt Başlık'
                                    width="auto">
                                    <template slot-scope="prop">
                                        <div class="new-line-text">
                                            {{prop.row.altBaslik}}
                                        </div>
                                    </template>
                                </el-table-column>                           
                                          
                                <el-table-column
                                    :label='$t("src.views.apps.genel.islem")'
                                    align="right"
                                    width="auto"
                                    fixed="right">
                                    <template slot-scope="scope">

                                        <el-tooltip :content='"Ürün Sayfasına Git"' :open-delay="500" placement="top" >
                                            <el-link target="_blank" icon="mdi mdi-redo-variant mdi-18px" style="margin-left: 15px !important; margin-bottom : 5px" :underline="false" :href="path+scope.row.link">
                                            </el-link>
                                        </el-tooltip>

                                        <el-tooltip :content='$t("src.views.apps.urun.liste.urunduzenle")' :open-delay="500" placement="top">
                                            <el-button v-on:click="routeUrun('Ürün Güncelle',scope.row, 'guncelle')" type="text" style="color: #f7ba2a; margin-left: 15px !important;" icon="mdi mdi-pen mdi-18px">
                                            </el-button>
                                        </el-tooltip>

                                        <el-tooltip :content='$t("src.views.apps.urun.liste.urunkaldir")' :open-delay="500" placement="top">
                                            <el-button
                                                v-if="selectIcon==='1'"
                                                @click="urunDurumDegis(scope.row,scope.$index,urunlerList,'0')"
                                                size="mini"
                                                class="buttonDel" style="margin-left: 15px !important"
                                                type="text"
                                                icon="mdi mdi-pause mdi-18px">
                                            </el-button>
                                        </el-tooltip>

                                        <!--
                                        <el-tooltip :content='"Detay"' :open-delay="500" placement="bottom">
                                            <el-button
                                                type="text" style="color: #2c3437; margin-left: 15px !important; "
                                                v-on:click="urunDetayButton(scope.row)">
                                                <i class="mdi mdi-note-outline mdi-24px" ></i>
                                            </el-button>
                                        </el-tooltip>
                                        -->

                                        <el-tooltip :content='$t("src.views.apps.sayfa.liste.yayindanKaldir")' :open-delay="500" placement="top">
                                            <el-button
                                                v-if="selectIcon==='0'"
                                                @click="urunDurumDegis(scope.row,scope.$index,urunlerList,'1')"
                                                type="text" style="margin-left: 15px !important"
                                                icon="mdi mdi-play mdi-18px">
                                            </el-button>
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- LİSTE TİPİ 1 BİTİŞ -->
                            
                            <!-- LİSTE TİPİ 2 OLAN (CART) BAŞLANGIÇ -->
                            <div :key="listKey" v-if="listeTipi === '2'" class="list-container box grow flex column" style="width: 100%;" v-loading="loading || searchLoading"
                            :element-loading-text='loading ? $t("src.views.apps.urun.liste.loading") : $t("src.views.apps.urun.liste.searchLoading")'
                            element-loading-spinner="el-icon-loading"
                            element-loading-background="rgba(255, 255, 255, 1)">
                                <div class="list scrollable only-y box grow" v-loading="stateLoading"
                                    :element-loading-text='$t("src.views.apps.urun.liste.stateLoading")'
                                    element-loading-spinner="el-icon-loading"
                                    element-loading-background="rgba(255, 255, 255, 1)">
                                    <div v-if="urunlerList.length > 0" id="flip-list-demo" class="demo">
                                        <el-col v-for="(i,index) in urunlerList" :key="index" class="item" :style="{width: urunKutuBoyut}">
                                                <div class="wrapper card-shadow--medium">
                                                    <div class="image p-20" style="background: #eeeeee">
                                                        <div v-if="i.resim" class="bg" :style="'background-image: url('+imagepath+i.resim+')'"></div>
                                                        <div v-else class="bg" :style="'background-image: url('+defaultImage+')'"></div>
                                                        <!-- <el-carousel :autoplay="false" trigger="click" height="150px">
                                                            <el-carousel-item v-for="item in i.resim" :key="item">
                                                                <div class="bg" :style="'background-image: url('+imagepath+item+')'"></div>
                                                            </el-carousel-item>
                                                        </el-carousel> -->
                                                    </div>
                                                    <div class="detail p-20">
                                                        <div class="name mt-20">
                                                            {{i.baslik}}
                                                        </div>    
                                                        <div class="name mt-7">
                                                            {{i.altBaslik}}
                                                        </div>                                                      
                                                        
                                                        <div class="buttons flex justify-space-around">
                                                            <div>                                                                
                                                               
                                                                <el-tooltip :content='$t("src.views.apps.urun.liste.yayinaAl")' :open-delay="250" placement="top">
                                                                    <button v-if="selectIcon == '0'" class="ml-5" v-on:click="urunDurumDegis(i.sayfaID, 1)">
                                                                        <i class="mdi mdi-play mdi-18px"></i>
                                                                    </button>
                                                                </el-tooltip>

                                                                <el-tooltip :content='$t("src.views.apps.urun.liste.yayindanKaldir")' :open-delay="250" placement="top">
                                                                    <button v-if="selectIcon == '1'" class="ml-5" v-on:click="urunDurumDegis(i.sayfaID, 0)">
                                                                        <i class="mdi mdi-pause mdi-18px"></i>
                                                                    </button>
                                                                </el-tooltip>

                                                                <el-tooltip :content='$t("src.views.apps.urun.liste.urunDetay")' placement="top">
                                                                    <button class="ml-5" @click="openDialog(i)">
                                                                        <i class="mdi mdi-note-outline"></i>
                                                                    </button>
                                                                </el-tooltip>                                                                

                                                                <el-tooltip :content='$t("src.views.apps.urun.liste.urunduzenle")' :open-delay="250" placement="top">
                                                                    <button class="ml-5" v-on:click="routeUrun('Ürün Güncelle',i,'guncelle')">
                                                                        <i class="el-icon-edit"></i>
                                                                    </button>
                                                                </el-tooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
     
                                                <div>
                                                <el-dialog :visible.sync="i.dialogFormVisible" :modal-append-to-body="false">
                                                    <span slot="title" class="dialog-title">Ürün Detay</span>
                                                    <el-form :model="i">
                                                        <el-form-item>
                                                        <el-col :lg="24" :md="24" :sm="24" :xs="24">
                                                            
                                                            <el-col v-if="i.resim" :lg="24" :md="24" :sm="24" :xs="24" class="text-center" style="">
                                                                    <el-popover
                                                                    transition="el-fade-in-linear"
                                                                    placement="left"
                                                                    width="auto"
                                                                    trigger="hover">
                                                                    <img style="max-height: 600px" v-if="i.resim" :src="imagepath+i.resim" :alt="i.baslik">
                                                                    <span v-else>Resim Yok.</span>
                                                                    <img v-if="i.resim" style="max-height: 130px !important" slot="reference" :src="imagepath+i.resim" :alt="i.baslik">
                                                                    <i v-else style="font-size: 24px; cursor: pointer; color: #337ab7" slot="reference" class="el-icon-picture"></i>
                                                                </el-popover>
                                                            </el-col>

                                                            <el-col v-else :lg="24" :md="24" :sm="24" :xs="24" class="text-center">
                                                                <el-popover
                                                                transition="el-fade-in-linear"
                                                                placement="left"
                                                                width="auto"
                                                                trigger="hover">
                                                                    <div>No Image.</div>
                                                                    <i style="font-size: 24px; cursor: pointer; color: #337ab7" slot="reference" class="el-icon-picture"></i>
                                                                </el-popover>
                                                            </el-col>
                                                            <br><br>

                                                            <el-col :lg="24" :md="24" :sm="24" :xs="24">
                                                                <el-col :lg="4" :md="4" :sm="4" :xs="4"><b>Başlık</b></el-col>
                                                                <el-col :lg="1" :md="1" :sm="1" :xs="1"><b>:</b></el-col>
                                                                <el-col :lg="19" :md="19" :sm="19" :xs="19">{{ i.baslik }}</el-col>
                                                            </el-col>


                                                            <el-col :lg="24" :md="24" :sm="24" :xs="24">
                                                                <el-col :lg="4" :md="4" :sm="4" :xs="4"><b>Alt Başlık</b></el-col>
                                                                <el-col :lg="1" :md="1" :sm="1" :xs="1"><b>:</b></el-col>
                                                                <el-col :lg="19" :md="19" :sm="19" :xs="19">{{ i.altBaslik }}</el-col>
                                                            </el-col>


                                                            <el-col :lg="24" :md="24" :sm="24" :xs="24">
                                                                <el-col :lg="4" :md="4" :sm="4" :xs="4"><b>İçerik</b></el-col>
                                                                <el-col :lg="1" :md="1" :sm="1" :xs="1"><b>:</b></el-col>
                                                                <el-col :lg="19" :md="19" :sm="19" :xs="19" class="text-justify" style="word-break: break-word; overflow-wrap:break-word;">{{ i.icerik }}</el-col>
                                                            </el-col>
                                                                
                                                        </el-col>                                                        
                                                        </el-form-item>
                                                        
                                                    </el-form>
                                                </el-dialog>
                                                </div>       
                                                                
                                            </el-col>
                                    </div>

                                    <div v-else style="height: 200px !important; display: flex; justify-content: center; align-items: center; font-size: 14px !important">
                                        Gösterilecek ürün bulunamadı.
                                    </div>

                                </div>
                            </div>
                            <!-- LİSTE TİPİ 2 OLAN BİTİŞ -->
                        </div>
                        
                    </div>

                    <el-row v-if="!(loading || searchLoading) && this.urunlerList.length > 0">
                        <el-pagination
                            background
                            layout="sizes,prev, pager, next, jumper, total"
                            :total="total"
                            @size-change="handleSizeChange"
                            @current-change="pageChange"
                            :page-sizes="[12, 24, 36, 48, 96]"
                            :current-page.sync="page"
                            :next-click.sync="page"
                            :prev-click.sync="page"
                            @next-click="pageChange"
                            @prev-click="pageChange">
                        </el-pagination>
                    </el-row>
                </el-col>
                <!-- ÜRÜN LİST BİTİŞ -->
            </el-row>            
        </div>

    </div>
</template>

<script>

    import urunService from '../../../WSProvider/UrunService'

    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'
    import JQuery from 'jquery';
    

    let $ = JQuery;

    export default {
        name: "UrunListe",
        components: {
        },
        data() {
            return {

                isDesktop: false,
                formLabelWidth: '160px',

                listKey: 0,

                urunlerCol: 0, // Sidebar acık/kapalı durumuna gore col genisligi değişkenleri buna atanmalı! Değişkenleri data içinde değişmek yeterli olsun diye!!!!
                urunColDar: 19, // Sidebar açıkken ürünlerin oldugu div kaç col yer kaplayacak
                urunColGenis: 24, // Sidebar kapalıyken ürünlerin oldugu div kaç col yer kaplayacak

                urunKutuGenis: '20%', // Sidebar kapalıyken kutuların boyutunun ne olacagı
                urunKutuDar: '25%', // sidebar açıkken ürünlerin boyutunun ne olacagı
                urunKutuBoyut: '', // Sidebar acık/kapalı durumuna gore kutu genişliği değişkenleri buna atanmalı! Değişkenleri data içinde değişmek yeterli olsun diye!!!!

                              
                // Pagination
                sizes: 12,
                page: 0,
                postPage: 0,
                total: null,
                
                defaultImage: '@/assets/images/demirag_logo.png', // ürünün resmi yoksa default bir resim konulacak. Firmanın logosu
                urunlerList: [
                    
                {
                    dialogFormVisible: false
                },
            
                ],
                loading: false,
                stateLoading: false,
                searchLoading: false,

               

                path: urunService.path,
                imagepath: urunService.imagePath,
                imagepathKucuk: urunService.imagePathKucuk,
                
                // Page
                selection: '',
                selectIcon: '1',
                listeTipi: '2',
                radioListe: '2',
                radio: '1',

                sidebarOpen: false,
                productFilterName: "",

            }
        },
        created(){
            this.checkDevices();
        },

        mounted() {
            window.addEventListener('resize', this.checkDevices);
            // Başlangıçta siderbar acık gelecek. Store'da da saklanabilir! (Daha önce kapattıysa siteyi tekrar actığında kapalı gelmesi için)
            this.sidebarOpen = true;
            this.urunlerCol = this.urunColDar
            this.urunKutuBoyut = this.urunColDar

            this.getProductList();
           

            const self = this;
            EventBus.$on("urunListeGuncelle", function(bool){
                self.page = 1;
                self.postPage = 0;
                self.resetFilter();
                self.filterProduct();
            })     
        },

        beforeDestroy(){
            window.removeEventListener('resize', this.checkDevices)
        },

        watch: {
            page(val) {
                this.postPage = (val - 1) * this.sizes;
            },
           
            productFilterName(val, oldVal){
                this.page = 1;
                this.postPage = 0;
            },
           
            discountFilter(){
                this.page = 1;
                this.postPage = 0;
            },
            homePageFilter(){
                this.page = 1;
                this.postPage = 0;
            },
            trendFilter(){
                this.page = 1;
                this.postPage = 0;
            },
            sidebarOpen(val){
                if(val == false){
                    setTimeout(() => {
                        $('#leftSideBar').hide();
                        this.urunKutuBoyut = this.urunKutuGenis;
                        this.urunlerCol = this.urunColGenis
                    }, 400)
                }else{
                    $('#leftSideBar').show();
                    this.urunKutuBoyut = this.isDesktop ? this.urunKutuDar : '100%';
                    this.urunlerCol = this.urunColDar
                }
            }            
        },
        methods: { 

            checkDevices(){
                this.isDesktop = window.innerWidth >= 821;
            },

            openDialog(urun) {
                this.$set(urun, 'dialogFormVisible', true);
            },
                       
            refreshPage(){
                this.page = 1;
                this.postPage = 0;
                this.getProductList();
                this.resetFilter();
            },

            resetFilter(){
                this.productFilterName= "";
            },

            filterProduct(){
                if(this.productFilterName.length > 0){
                    this.searchProduct();
                }else{
                    this.getProductList();
                }
            },            

            searchProduct(){
                try{
                    this.searchLoading = true;
                    //arama, kategoriID, markaID, minFiyat, maxFiyat, indirimUrun, trendUrun, anasayfaUrun, baslangic, limit
                    urunService.productSearch(this.productFilterName, this.selectIcon, this.postPage, this.sizes).then(response => {
                        localStorage.setItem("userDataDemirag", response.token)
                        if (response.status == 200) {
                            this.total = response.count;
                            response.data.forEach(el => {
                                el = functions.removeSlashesAndDecodeStrings(el)
                            })
                            this.urunlerList = response.data;
                            //this.getProductList();
                        }
                        this.searchLoading = false;

                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.total = 0
                                this.urunlerList = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }else{
                            //notification.Status(503, this)
                        }
                        this.searchLoading = false;
                    }) 
                }catch(e){
                    // notification.Status("danger", this, "Product search failed. An error has occurred")
                    this.searchLoading = false
                }
            },

            
            handleChangeListe(event) {
                this.listeTipi = event;
            },

           

            routeUrun(name, scope) {
                if (scope) {
                    this.$store.commit('changeUrunUpdateData', scope.sayfaID);
                }
                this.routeSayfa(name)
            },

            getProductList() {
                try{
                    this.loading = true;
                    urunService.urunTumListesi(this.selectIcon, this.postPage, this.sizes).then((response) => {
                        //console.log({response})
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token)
                            this.total = parseInt(response.count);
                            response.data.forEach(el => {
                                // el.icerik = functions.htmlDecode2((el.icerik));
                                el = functions.removeSlashesAndDecodeStrings(el)
                                el.fiyat = functions.decodeMoney(el.fiyat);
                                el.fiyatBirim = functions.decodeMoney(el.birimFiyat);
                                el.eskiFiyat = functions.decodeMoney(el.eskiFiyat);
                            })
                            this.urunlerList = response.data;
                        }
                        this.loading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.total = 0
                                this.urunlerList = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }else{
                            //notification.Status(503, this)
                        }
                        this.loading = false;
                    })
                }catch(e){
                    // notification.Status("danger", this, "An error occurred while fetching the product list.")
                    this.loading = false
                }
            },

            urunDurumDegis(selection, durum) {
                var msg = durum == '1' ? this.$t("src.views.apps.urun.liste.urunyayin") : this.$t("src.views.apps.urun.liste.uruntaslak")
                this.openConfirmDialog(msg).then(() => {
                    try{
                        this.stateLoading = true;
                        urunService.urunDurumGuncelle(selection, durum).then((response) => {
                            localStorage.setItem("userDataDemirag", response.token)
                            if (response.status === 200) {
                                this.getProductList();
                            }
                            notification.Status("success", this, response.msg);
                            this.stateLoading = false;
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }
                                else notification.Status("errorMsg", this, error.errMsg)
                            }else{
                                //notification.Status(503, this)
                            }
                            this.stateLoading = false;
                        })
                    }catch(e){
                        this.stateLoading = false
                    }
                })
            },

            indexMethod(index) {
                return ((this.page - 1)* 10) + index + 1;
            },
            pageChange(val) {
                this.postPage = (val - 1) * this.sizes;
                this.filterProduct()
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },
            handleSizeChange(val) {
                this.sizes = val;
                // this.getProductList();
                this.filterProduct();
            },
            handleChange(event) {
                this.page = 1;
                this.postPage = 0;
                this.selectIcon = event;
                this.getProductList();
                this.filterProduct()
            },    
        }
    }


</script>


<style lang="scss">
    @import "../../../assets/scss/_variables.scss";

    .cokluUrunDialog{
        padding-left: 20px !important; 
        padding: 20px !important; 
        min-height: 100px !important;
        max-height: 500px !important;
        // height: 500px;
        overflow-x: hidden
    }

    .productSettings{
        .el-checkbox{
            width: 100% !important
        }
    }
    .el-table .info-row {
        background: rgba(125, 199, 225, 0.45);
    }

    .el-table .error-row {
        background: rgba(249, 0, 26, 0.08);
    }

    .el-table .success-row {
        background: rgba(3, 249, 0, 0.08);
    }

    .el-table .warning-row {
        background: rgba(245, 249, 0, 0.08);
    }

    .priceRadio{
        .el-radio__input{
            border-radius: 2px !important;
        }
        .el-radio__inner{
            border-radius: 2px !important;
        }
    }

</style>

<style lang="scss" scoped>
    @import "../../../assets/scss/_variables.scss";

    // .page-header{
    //     background: $background-color !important;
    // }

    .main{
        // background: white !important;
        padding: 5px !important;
    }

    #product-search-btn{
        font-size: 12px !important
    }
    
    .refresh {
        color: #fff;
        float: right;
        padding: 0 !important;
        transition: all 0.5s;

    }

    .refresh:hover {
        transform: rotate(360deg);
    }

    .el-pagination {
        margin-top: 20px;
        float: right;
    }

    .el-radio-button--mini .el-radio-button__inner label {
        position: relative;
        bottom: 5px;
    }

    .kaldirilmis {
        background: #ec205f;
        color: white;
        font-weight: bold;
        font-size: 16px;
        padding: 10px
    }

</style>

<style lang="scss">
    @import "../../../assets/scss/_variables.scss";

    .page-ecommerce-products {
        .el-table{
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07), 0 1px 3px 0 rgba(0, 0, 0, 0.065);
        }

        .el-table__body-wrapper{
            &::-webkit-scrollbar {
                width: 5px !important;
                height: 15px !important;
            } 
            &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
                background: transparent !important;
                border-radius: 5px !important;
            }      
            &::-webkit-scrollbar-thumb {
                background: transparentize($text-color, 0.85) !important;
            }
            &:hover::-webkit-scrollbar-thumb {
                background: transparentize($text-color, 0.85) !important;
                
                &:hover {
                    background: transparentize($text-color, 0.75) !important;
                }
            }
            &:hover::-webkit-scrollbar-track {
                background: transparentize($text-color, 0.95) !important;
            }
        }
    }

    .slide-fade-enter-active {
        transition: all .3s ease;
    }


    @media (min-width: 768px) {
        .el-radio-button--mini .el-radio-button__inner {
            padding: 0 10px !important;
            font-size: 11px;
            border-radius: 0;
        }
    }


    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }
</style>

<style lang="scss" scoped>
    @import '../../../assets/scss/_variables';

    .page-ecommerce-products {
        .sidebar {
            width: 300px;
            margin-right: 20px;
            margin-right: 10px;
            margin-left: -10px;

            .scroller {
                padding: 10px;
                padding-top: 0px;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
            }

            .widget {
                background: white;
                border-radius: 4px;
                margin-bottom: 20px;
                box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.07), 0 3px 6px 0 rgba(0, 0, 0, 0.065);
                overflow: hidden;

                &.close-filter-box {
                    display: none;
                    text-align: center;

                    button {
                        width: 100%;
                        border: none;
                        text-transform: uppercase;
                        outline: none;
                        font-family: inherit;
                        font-weight: bold;
                        padding: 5px 0px;
                        border-bottom: 2px solid;
                        background: white;
                        color: #ec205f;
                        cursor: pointer;
                    }
                }

                &.select-color {
                    ul, li {
                        padding: 0;
                        list-style: none;
                        margin: 0;
                    }

                    li {
                        margin-bottom: 10px;
                    }

                    .color-box {
                        background: transparent;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                        margin-right: 10px;
                    }
                }

                .title {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    padding: 15px 20px;
                }

                .content {
                    padding: 15px 20px;
                }
            }
        }

        .toggle-filter-box {
            padding: 10px;
            padding-top: 0px;
            text-align: right;
            display: none;

            button {
                border: none;
                text-transform: uppercase;
                outline: none;
                font-family: inherit;
                font-weight: bold;
                padding: 1px 2px;
                border-bottom: 2px solid;
                color: #13ce66;
                background: transparent;
                cursor: pointer;
            }
        }

        .list {
            .item {
                display: block;
                // width: 25%;
                height: 400px;
                padding: 0 10px;
                padding-bottom: 20px;
                box-sizing: border-box;
                float: left;

                .wrapper {
                    box-sizing: border-box;
                    height: 100%;
                    position: relative;
                    cursor: pointer;
                    transition: all .25s;

                    .image {
                        box-sizing: border-box;
                        height: 150px;
                        width: 100%;
                        background-color: white;
                        padding-bottom: 10px;

                        .bg {
                            background-color: white;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center center;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .detail {
                        padding-top: 10px;

                        .rate {
                            margin-top: 10px;

                            & > div {
                                margin: 0 auto;
                                display: block;
                                width: 120px;
                            }
                        }

                        .name {
                            text-transform: uppercase;
                            font-weight: bold;
                            text-align: center;
                            padding: 10px;
                            padding-bottom: 5px;
                        }

                        .desc {
                            text-align: center;
                            font-size: 14px;
                            opacity: .5;
                        }

                        .price {
                            text-align: center;
                            font-weight: bold;
                            font-size: 22px;
                            padding: 10px;
                            color: #13ce66;
                        }
                    }

                    .buttons {
                        position: absolute;
                        left: 20px;
                        bottom: 5px;
                        right: 20px;

                        button {
                            background: white;
                            color: $text-color;
                            border: none;
                            text-transform: uppercase;
                            outline: none;
                            font-family: inherit;
                            font-weight: bold;
                            padding: 3px 7px;
                            font-size: 20px;
                            cursor: pointer;
                        }
                    }

                    &:hover {
                        box-shadow: 0 8px 16px 0 rgba(40, 40, 90, 0.09), 0 3px 6px 0 rgba(0, 0, 0, 0.065), 0px 10px 0px 0px $color-second;
                    }
                }
            }
        }
    }

    @media (max-width: 1400px) {
        .page-ecommerce-products {

            .list {
                .item {
                    width: 33.33%;
                }
            }
        }
    }


    @media (max-width: 1100px) {
        .page-ecommerce-products {

            .list {
                .item {
                    width: 50%;
                }
            }
        }
    }


    @media (max-width: 900px) {
        .page-ecommerce-products {

            .sidebar {
                width: 200px;
            }

        }
    }

    @media (max-width: 768px) {
        .page-ecommerce-products {

            .sidebar {
                width: 230px;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                margin: 0;
                z-index: 999;
                transform: translateX(-100%);
                transition: all .25s;
                background: white;

                .scroller {
                    padding: 15px;
                    padding-top: 20px;
                }

                .widget {
                    &.close-filter-box {
                        display: block;
                    }
                }

                &.open {
                    transform: translateX(0%);
                    box-shadow: 3px 0px 10px -3px rgba(0, 0, 0, 0.4);
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }
            }

            .toggle-filter-box {
                display: block;
            }

        }
    }

    @media (max-width: 480px) {
        .page-ecommerce-products {

            .list {
                .item {
                    width: 100%;
                }
            }
        }
    }
    .flip-list-move {
        transition: transform 1s;
    }

    
</style>

<style>

    .dialog-title {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #071A3A;
        color: white;
        height: 40px;
    }

    .el-dialog__header{
        padding: 0px !important;
    }

</style>